import React, { useEffect, useRef, useState } from "react";
import  nispandLogo  from "../../Assets/NispandLogo.svg";
// import nispandLogo from '../../../../assets/NispandLogo.svg'

const OnBoardCarousel = () => {
  const images = [
    "https://nispand-prod.oss-ap-south-1.aliyuncs.com/NispandBusiness/login.jpg",
    "https://nispand-prod.oss-ap-south-1.aliyuncs.com/NispandBusiness/setpassword.jpg",
    "https://nispand-prod.oss-ap-south-1.aliyuncs.com/NispandBusiness/verify.jpg",
    "https://nispand-prod.oss-ap-south-1.aliyuncs.com/NispandBusiness/password.jpg",
  ];

  const tagline = [
    "Over 5000 employees have boosted productivity, focus, and mental health with Nispand.",
    "Transform your company's wellness culture. Join the Nispand Revolution",
    "Employee burnout costs businesses billions each year. Enhance mental health and productivity of your employees with Nispand.",
    "Productivity drops by 20% when employees are stressed. See how Nispand has helped over 100 companies boost their team's performance.",
  ];
  const [currentImage, setCurrentImage] = useState(0);
  const [currentLine, setCurrentLine] = useState(0);

  const intervalRef = useRef();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage === images.length - 1 ? 0 : prevImage + 1
      );
      setCurrentLine((prevLine) =>
        prevLine === tagline.length - 1 ? 0 : prevLine + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => {
      // Clear interval only when the component is unmounted
      clearInterval(intervalRef.current);
    };
  }, []);
  return (
    <>
      <div className="img-div">
        <img src={images[currentImage]} alt="login-img" className="login-img" />
      </div>
      <div className="img-title">
        <img src={nispandLogo} alt="nispand-logo" className="nispand-logo" />
      </div>
      <div className="img-title" style={{width: '70%'}}>
        <h2 className="img-tagline" >{tagline[currentLine]}</h2>
      </div>
    </>
  );
};

export default OnBoardCarousel;
