import React from 'react'
import { Route,Routes } from 'react-router-dom'
import { AuthRoutes } from './Constants/routes'

const Authentication = () => {
  return (
    <div>
      <Routes>
      {AuthRoutes.map(({ path, Component }) => (
          <Route element={<Component />} path={path} key={path} />
        ))}
      </Routes>
    </div>
  )
}

export default Authentication
