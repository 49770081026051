
import { lazy } from "react";
const Authentication = lazy(() => import("../../Authentication"));
const Members = lazy(() => import("../Members/Components/AllMembers"));
const Dashboard = lazy(() => import("../Components/Dashboard"));
const Billing = lazy(() => import("../Billing"));
const AddMoreLicences = lazy(() => import("../Billing/Components/AddMoreLicences"));
const Settings = lazy(() => import("../Settings"))
const Help = lazy(()=>import('../Help'))
const Accounts = lazy(()=>import("../Members/Components/Accounts"))

export const DashboardRoutes = [
  {
    Component: Dashboard,
    path: "home",
    exact: true,
    id: "dashboardHome",
    // routePath: "home",
    name: "Home",
    icon: "ri-dashboard-fill",
  },
  {
    Component: Members,
    path: "members",
    exact: false,
    id: "dashboardHomeMembers",
    // routePath: "members",
  },
  {
    Component: Settings,
    path: "settings",
    exact: false,
    id: "dashboardHomeSettings",
    // routePath: "members",
  },
  {
    Component: Billing,
    path: "billing",
    exact: false,
    // routePath: "billing",
  },
  {
    Component: AddMoreLicences,
    path: "billing/add-licences",
    exact: true,
    // routePath: "billing/add-licences",
  },
  {
    Component: Help,
    path: "help",
    exact: false,
    id: "dashboardHomeHelp",
    // routePath: "members",
  },
  {
    Component: Accounts,
    path: "members/accounts",
    exact: false,
    id: "membersaccounts",
    // routePath: "members",
  },
];


