import { Container, Row, Col } from "react-bootstrap";
import "./style.css";
import Sidebar from "./Components/Sidebar";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <>
      {/* Dashboard */}
      <Container fluid className="dashboard-container dashboard-visible p-0">
        <Row className="m-0">
          <Col lg={3} xxl={2} className="border-end" style={{ width: '18%' }}>
            <Sidebar />
          </Col>
          <Col lg={9} xxl={10} style={{ width: '82%' }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Outlet />
            </Suspense>
          </Col>
        </Row>
      </Container>
      <Container className="error-desktop">
        This app works best on Desktop/PC.
      </Container>
    </>
  );
};
export default Dashboard;