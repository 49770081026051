import axios from "axios";
// import baseDomain, { generateCred } from '../../../../Constants/apiRoutes'
import baseDomain, {
  generateCred,
  secureStsCredApi,
  secureStsVerifyApi,
} from "./api";
import { authBaseDomain, stage } from "../../../Constants/authApis";

export const getSecureStsCred = (params) => {
  return axios.get(`${authBaseDomain}${stage}${secureStsCredApi}`, {
    params,
    headers: params.headers,
  });
};

export const regenerateSecureStsToken = () => {
  return axios.get(`${authBaseDomain}${stage}${secureStsVerifyApi}`);
};
