import React, { useState } from "react";
import ValidationTick from "../../../../Assets/Vector.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SetPassword = ({
  handlePasswordChange,
  handleConfirmPasswordChange,
  password,
  confirmPassword,
  characterCount,
  isCapital,
  isNumber,
  isSmall,
  isDisabled,
  handleSubmit,
}) => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const passwordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  return (
    <div className="login-form">
      <h1>Set your password</h1>
      <p className="login-para">
        To get started, let's set your password. Select a password that will be
        easy for you to remember and click 'Set Password' to continue.
      </p>
      <form>
        <div class="form-floating mt-3 mb-3">
          <input
            type={showPassword ? "text" : "password"}
            class="form-control"
            id="pwd"
            placeholder="Enter password"
            name="pswd"
            value={password}
            onChange={handlePasswordChange}
          />
          <label for="pwd" className="form-label">
            Enter Password
          </label>
          <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              className="visible-password-icon"
              onClick={passwordVisibility}
              style={{cursor: 'pointer'}}
            />
        </div>
        <div class="form-floating mt-3 mb-3">
          <input
            type={showPassword1 ? "text" : "password"}
            class="form-control"
            id="crfmpswd"
            placeholder="Enter password"
            name="crfmpswd"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
          <label for="pwd" className="form-label">
            Confirm Password
          </label>
          <FontAwesomeIcon
              icon={showPassword1 ? faEyeSlash : faEye}
              className="visible-password-icon"
              onClick={passwordVisibility1}
              style={{cursor: 'pointer'}}
            />
        </div>
        <div className="validation-types">
          <div className="validation">
            <span className="validation-signs">
              {characterCount >= 8 && (
                <img src={ValidationTick} alt="tick-img" className="tick-img" />
              )}
            </span>
            {characterCount >= 8 ? (
              <p style={{ color: "#148B47" }}>8+</p>
            ) : (
              <p style={{ color: "#D2402D" }}>8+</p>
            )}
            {characterCount >= 8 ? (
              <p style={{ color: "#148B47" }}>Characters</p>
            ) : (
              <p style={{ color: "#D2402D" }}>Characters</p>
            )}
          </div>
          <div className="validation">
            <span className="validation-signs">
              {isCapital ? <img src={ValidationTick} alt="tick-img" /> : ""}
            </span>
            {isCapital ? (
              <p style={{ color: "#148B47" }}>AA</p>
            ) : (
              <p style={{ color: "#D2402D" }}>AA</p>
            )}
            {isCapital ? (
              <p style={{ color: "#148B47" }}>Uppercase</p>
            ) : (
              <p style={{ color: "#D2402D" }}>Uppercase</p>
            )}
          </div>
          <div className="validation">
            <span className="validation-signs">
              {isSmall ? <img src={ValidationTick} alt="tick-img" /> : ""}
            </span>
            {isSmall ? (
              <p style={{ color: "#148B47" }}>aa</p>
            ) : (
              <p style={{ color: "#D2402D" }}>aa</p>
            )}
            {isSmall ? (
              <p style={{ color: "#148B47" }}>Lowercase</p>
            ) : (
              <p style={{ color: "#D2402D" }}>Lowercase</p>
            )}
          </div>
          <div className="validation">
            <span className="validation-signs">
              {isNumber ? <img src={ValidationTick} alt="tick-img" /> : ""}
            </span>
            {isNumber ? (
              <p style={{ color: "#148B47" }}>80</p>
            ) : (
              <p style={{ color: "#D2402D" }}>80</p>
            )}
            {isNumber ? (
              <p style={{ color: "#148B47" }}>Numbers</p>
            ) : (
              <p style={{ color: "#D2402D" }}>Numbers</p>
            )}
          </div>
        </div>
        <button
          className={isDisabled ? "btn-disabled" : "btn-login"}
          disabled={!isDisabled ? false : true}
          onClick={handleSubmit}
        >
          Set Password
        </button>
        <p className="back-para">
          <div className="back-login" onClick={() => navigate("/auth/login")}>
            Back to Login
          </div>
        </p>
      </form>
    </div>
  );
};

export default SetPassword;
