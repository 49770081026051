import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import Loader from '../../../../Components/Loader'

const RecoveryPasswordModal = ({ handleChange, handleSubmit, isLoading }) => {

    const navigate = useNavigate()
    return (<>
        <div className='login-form recovery-password-wrapper'>
            <h1>Password Recovery</h1>
            <p>Please provide your email and recover your password.</p>

            <form>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="form-floating mb-3 mt-3">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter email"
                            name="email"
                            autoComplete="off"
                            onChange={handleChange}
                        />
                        <label htmlFor="email" className="form-label">
                            Email Address
                        </label>
                    </div>
                )}
                <button className='btn-login' onClick={handleSubmit}>Continue</button>
                {/* <ToastContainer /> */}
                <p className='back-para'><a onClick={() => navigate("/auth/login")}>Back to Login</a></p>
            </form>

        </div>
    </>)
}

export default RecoveryPasswordModal
