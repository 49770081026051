
// import Login from "../../../Auth/Login";
import Login from "../Components/Login";

export const AuthRoutes =[
    {
        Component: Login,
        path: '/login',
        exact: true,
        id : 'authSignup',
        routePath: '/auth/login',
    },
]