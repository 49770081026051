import OSS from "ali-oss";
import { getSecureStsCred, regenerateSecureStsToken } from "./signup.upload.API";
import axios from "axios";

const getOssClient = ({
  securityToken,
  accessKeyId,
  accessKeySecret,
  ossBucketName,
  ossBucketRegion,
}) => {
  const client = new OSS({
    region: ossBucketRegion,
    accessKeyId,
    accessKeySecret,
    stsToken: securityToken,
    bucket: ossBucketName,
    // Refresh the temporary access credential.
    refreshSTSToken: async () => {
      const { data } = await regenerateSecureStsToken();
      return {
        accessKeyId: data.data.accessKeyId,
        accessKeySecret: data.data.accessKeySecret,
        stsToken: data.data.securityToken,
      };
    },
  });

  return client;
};

export const formatMultiSelectData = (data) => {
  const formatedData = data.map(({ value }) => value);
  return formatedData;
};

// AUDIO_IMAGE
export const signupUploadFile = (file, type, onProgress, percentageW) =>{
  ; 
  return new Promise(async (resolve, reject) => {
    try {
      let uploadFile = file;
      const token = sessionStorage.getItem("signUpToken"); 
    //   const { data } = await getSecureStsCred({
    //     name: uploadFile.name,
    //     type,
    //   },
    //   {headers: {
    //     Authorization: token, 
    //   }}

    //   // const {data} = axios.get(`https://back-end-staging.nispand.com/v2/media/secure/sts/cred`,
    //   // {headers: {
    //   //     Authorization: token, // Include the token in the Authorization header
    //   //   }}  
    // )
    const { data } = await axios.get(
      `https://back-end-preprod.nispand.com/v2/media/secure/sts/cred`,
      {
        params: {
          name: uploadFile.name,
          type: type,
        },
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      }
    );

      const client = getOssClient(data.data);


      const { name, bucket } = await client.multipartUpload(
        data.data.fileLocation,
        uploadFile,
        {
          progress: (p) => {
            onProgress(p * percentageW);
          },
          partSize: 1024 * 1024 * 1, 
          parallel: 3,
        }
      );
      resolve(
        `https://${bucket}.${data.data.ossBucketRegion}.aliyuncs.com/${name}`
      );
    } catch (error) {
      reject({
        error: true,
        errorMessage: "Unable to upload data try again later",
      });
    }
  });
}


