import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
// import { composeWithDevTools } from 'redux-devtools-extension'
// import { composeWithDevTools } from '@redux-devtools/extension'
import reducers from './reducers'
import sagas from './sagas'
import { configureStore } from '@reduxjs/toolkit'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

export const store = configureStore({ 
  reducer: reducers, 
  middleware: () => [sagaMiddleware] });

  sagaMiddleware.run(sagas);

// export function configureStore(initialState) {
//   const store = createStore(
//     reducers,
//     initialState,
//     process.env.NODE_ENV === 'development' 
//       ? composeWithDevTools(applyMiddleware(...middlewares))
//       : compose(applyMiddleware(...middlewares))
//   )

//   sagaMiddleware.run(sagas)

//   return store
// }
