import React from "react";
import "./style.scss";
import { FaMinus, FaPlus } from "react-icons/fa6";

const PlanSelectViaSignUp = ({
  title,
  description,
  NOL,
  count,
  onIncrement,
  onDecrement,
  onCountChange,
}) => {
  return (
    <>
      <div className="planSelectDiv my-4">
        <div className="dashed p-3">
          <h2 className="heading">{title}</h2>
          <p className="text-color">{description}</p>
        </div>

        <div className="d-flex justify-content-between align-items-center p-3">
          <p className="m-0 nol">{NOL}</p>
          <div className="d-flex align-items-center">
            <button
              onClick={(e) => onDecrement(e.target.value)}
              className="counter-btn mx-1"
              style={{
                height: "36px",
                width: "36px",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #D9D9D9",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <FaMinus />
            </button>
            <input
              type="text"
              value={count}
              onChange={(e) => onCountChange(e.target.value)}
              className="counterSpan m-0"
              style={{
                border: "1px solid #D9D9D9",
                borderRadius: "8px",
                paddingRight: "5px",
                width: "92px",
                height: "36px",
                paddingLeft: "5px",
                fontSize: "14px",
                lineHeight: "18px",
                fontWeight: 400,
                color: "#000000",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            />
            <div
              className="counterSpan m-0 border-start-0"
              style={{
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                left: "-42px",
                position: "relative",
                color: "#595959",
                fontSize: "14px",
                lineHeight: "14px",
                fontFamily: "'Inter',sans-serif",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              users
            </div>
            <button
              onClick={(e) => {
                onIncrement(e);
              }}
              className="counter-btn mx-1"
              style={{
                position: "relative",
                left: "-36px",
                height: "36px",
                width: "36px",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #D9D9D9",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <FaPlus />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanSelectViaSignUp;
