import axios from "axios";
import { authBaseDomain, stage } from "../../../Constants/authApis";
// const authDomain = 'https://back-end-dev.nispand.com/v2';
// const authBaseDomain = 'https://17b8-203-132-133-126.ngrok-free.app/v2';

export const getLoginAPI = (payload) => {
    return axios.post(`${authBaseDomain}/auth/login`, payload)
}

export const forgetPassword = (payload) => {
    return axios.post(`${authBaseDomain}${stage}/auth/forgotpassword`, payload)
}

export const getOtpForVerifcation = (payload) => {
    return axios.post(`${authBaseDomain}${stage}/auth/verify-otp`, payload)
}

export const setPasswordForLogin = (payload) => {
    console.log(payload)
    return axios.post(`${authBaseDomain}/v2/auth/resetpassword`, payload)
}
